@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-customer .customer-form fieldset,
.customer-local-modal {
  .search {
    position: relative;

    .btn-hover {
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid $clr-ui-border;
      border-radius: 0 3px 3px 0;
      background-color: $clr-ui-bkg;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;

      &:before {
        font-family: 'icomoon';
        font-size: 19px;
        content: '\e918';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $clr-txt-note;
      }

      &:focus {
        border-color: $primary;
      }
    }
  }
}


.v2-customer {
  .customer-form {
    fieldset {
      position: relative;
      margin-bottom: 20px;
      border: 2px solid #F2F5F8;
      border-radius: 4px;

      .close {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $alert-darker;
        cursor: pointer;

        position: absolute;
        top: -10px;
        right: -10px;

        opacity: 1;

        &:before {
          font-family: 'icomoon';
          font-size: 9px;
          content: '\e919';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
        }
      }

      .customer-head {
        legend {
          font-weight: $bold;
          position: absolute;
          background: #F2F5F8;
          padding: 10px 20px;
          width: 100%;
        }
      }

      .table-group tr {
        cursor: default;
      }

      .table-group .buttons button {
        display: block;
        float: left;
        width: 36px;
        height: 36px;
        margin-left: 10px;
        background-color: #53b7e8;
        color: #fff;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        position: relative;

        &.edit, &.remove {
          &::before {
            font-family: 'icomoon';
            font-size: 17px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:disabled {
            opacity: 0.6;
            cursor: default;
          }
        }

        &.edit::before {
          content: '\e90a';
        }

        &.remove {
          background-color: $alert;
          &::before {
            font-size: 12px;
            content: '\e919';
          }
        }
      }

      label {
        display: block;
        width: 100%;
        margin: 0 1% 20px;
        color: $clr-txt-title;
      }

      input[type='text'],
      input[type='date'],
      input[type='time'],
      input[type='file'],
      input[type='email'],
      input[type='number'],
      input[type='password'],
      select,
      textarea {
        display: block;
        width: 100%;
        height: 40px;
        padding: 0 15px;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        margin-top: 5px;
        background-color: #fff;
        font-size: $sz-content;
        font-family: $Source;
        color: $clr-txt-content;
        background-color: #fff;
        transition: $transition;

        &.hidden {
          display: none;
        }

        &:focus {
          border-color: $primary;
          outline: none;
        }

        option {
          color: $clr-txt-content;
          padding: 3px 0;
        }
      }

      select {
        background-color: #F2F5F8;
      }

      .selectCustom {
        border-radius: 0;

        .react-select__control {
          min-height: 40px;
          margin-top: 5px;
          border-radius: 3px;
          box-shadow: 0 0 0 0px transparent;
          border-color: #F2F5F8;
          background-color: #F2F5F8;

          .react-select__value-container {
            padding-top: 3px;
          }

          &.react-select__control--is-focused {
            border-color: $primary;
          }

          input[type='text'] {
            height: 13px;
          }

          .react-select__indicator-separator {
            display: none;
          }

          .react-select__dropdown-indicator {
            color: $clr-v2-heavier-grey;
          }
        }
      }

      .fantasyname {
        width: 97%;
      }

      .colspan-4 {
        width: 70%;
      }

      .colspan-1-10p {
        width: 8%;
      }

      .colspan-1-15p {
        width: 15%;
      }

      .colspan-1-20p {
        width: 21.8%;
      }

      .colspan-1-half {
        width: 22.5%;
      }

      .colspan-1-third {
        width: 34%;
      }

      .colspan-1-quarter {
        width: 48%;
      }

      .customer-info {
        padding: 50px 0 10px 30px;
        display: flex;

        .avatar div {
          position: relative;
          width: 120px;
          height: 120px;
          border: 1px solid $primary;
          border-radius: 80px;
          background-color: #F2F5F8;
          text-align: center;
          margin-top: 20px;
          padding: 48px 0;

          .icon {
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            border: 2px solid white;
            border-radius: 30px;
            background-color: #F2F5F8;
            top: 80px;
            left: 90px;
            padding: 2px;
            cursor: pointer;

            .icon-camera {
              color: $clr-v2-soft-red;
            }
          }

          #avatar-preview {
            top: -1px;
            left: -1px;
            width: 120px;
            height: 120px;
            border-radius: 80px;
            position: absolute;
          }
        }

        .customer-fields {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          border-left: 2px solid #F2F5F8;
          margin-left: 30px;
          padding-left: 30px;
        }
      }

      .customer-address {
        padding: 50px 18px 10px 18px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media screen and (max-width: 1108px) {
    .customer-form {
      fieldset {
        .customer-info {
          label:nth-child(2) {
            padding-top: 20px;
          }
        }
        .customer-address-fix {
          label:nth-child(6) {
            width: 20%;
          }

          label:nth-child(7) {
            width: 10.5%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .customer-form {
      fieldset {
        .customer-info {
          flex-wrap: wrap;
          justify-content: center;
          padding: 50px 30px 20px 30px;
          .customer-fields {
            width: 100%;
            margin: 20px 0 0 0;
            border-left: none;
            padding: 0;
          }
          label {
            width: 100%;
          }
        }

        .customer-address {
          label {
            width: 100%;
          }
        }
      }
    }
  }
}

.customer-local {

  .form > div.buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 40px;
  }

  .form > div.fields {
    display: grid;
    margin: 0;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: minmax(40px, auto);

    grid-gap: 20px;

    .col-fantasyname {
      grid-row: 1;
      grid-column: 1 / span 6;
    }

    .col-cnpj {
      grid-row: 1;
      grid-column: 7 / span 2;
    }

    .col-cep {
      grid-row: 2;
      grid-column: 1 / span 2;
    }

    .col-address {
      grid-row: 2;
      grid-column: 3 / span 6;
    }

    .col-number {
      grid-row: 3;
      grid-column: 1 / span 2;
    }

    .col-complement {
      grid-row: 3;
      grid-column: 3 / span 2;
    }

    .col-district {
      grid-row: 3;
      grid-column: 5 / span 4;
    }

    .col-country {
      grid-row: 4;
      grid-column: 1 / span 3;
    }

    .col-state {
      grid-row: 4;
      grid-column: 4 / span 1;
    }

    .col-city {
      grid-row: 4;
      grid-column: 5 / span 4;
    }

    @media screen and (max-width: 700px) {
      & {
        display: block;
      }
    }
  }
}