@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-report {
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      .per-page-label {
        display: none;
      }
    }

    .pagination {
      margin: 0;
      display: flex;
      flex-wrap: nowrap;

      li {
        list-style-type: none;
        margin-right: 5px;
        padding: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          font-weight: bold;
          background-color: navy;
          color: white;
          border-radius: 4px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .items-per-page {
      display: flex;
      align-items: center;

      .per-page-label {
        margin-right: 8px;
      }
    }
  }

  .v2-filter-searching {
    position: relative;

    .v2-filter-searching-nav {
      top: 18px;
      right: 15px !important;
      padding: 0;
    }
  }

  .report-table {
    tbody tr td {
      cursor: default;
    }

    .col-id {
      span {
        display: block;
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .col-user-name {
      span {
        display: block;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .col-pending-activity,
    .col-deadline {
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .col-pending-activity span {
      width: 160px;
    }

    .col-deadline span {
      width: 140px;
    }

    .col-description {
      span {
        display: block;
        width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}