.v2-page-content.consultant-panel {
  overflow-y: scroll;
  width: 100%;
  background-color: rgb(21, 70, 124);
  background: url(../../../assets/img/consultant-panel-background.svg);
  background-size: cover;
  max-height: 100vh;

  .logo {
    width: 100%;
    height: 4em;
    margin: 2em 0;
  }

  .v2-page-card {
    max-width: unset;
    margin: 0 auto;

    .v2-page-cardheader .v2-cardheader-filter .v2-filter-searching .v2-filter-searching-nav.close {
      right: 0 !important;
      padding-right: 27px;
    }
  }

  .v2-filter-notsearching .filter-title {
    position: absolute;
    left: 0;
    top: 0;
    margin: 17.14px 0 0 60px;
    text-transform: uppercase;
  }

  .container-cards > ul > li {
    width: 31.8% !important;
    border: unset;
    display: unset;
    margin: 10px;

    .content {
      border: 1px solid #dbdbdb;
      border-radius: 2px;
    }

    .contentRole {
      .norm {
        border-bottom-color: #dbdbdb;
        box-shadow: unset;
        margin: 0 20px;

        h5 {
          font-size: 12px;
        }
      }

      .containerBar {
        padding: 0;

        .progressBar {
          margin: 15px auto;
        }
      }
    }
  }

  .grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 80px;
  }
}

@media only screen and (max-width: 1178px) {
  .v2-page-content.consultant-panel {
    .container-cards > ul > li {
      width: 47% !important;
    }
  }
}

@media only screen and (max-width: 985px) {
  .v2-page-content.consultant-panel {
    .container-cards > ul > li {
      width: 95% !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .v2-page-content {
    .v2-header-menu {
      margin-right: 5%;
    }
    padding-left: 2px;
    padding-right: 2px;
    .card-body {
      padding: 2% 8% 0% 0% !important;
    }
  }
}

@media only screen and (min-width: 451px) and (max-width: 912px) {
  .v2-page-content {
    .v2-header-menu {
      // margin-right: 5%;
    }

    padding-left: 2px;
    padding-right: 2px;

    .card-body {
      padding: 2% 6% 0% 2% !important;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .v2-page-content {
    .v2-header-menu {
      // margin-right: 5%;
    }

    .v2-page-content.consultant-panel {
      .container-cards > ul > li {
        width: 50% !important;
      }
    }
    padding-left: 2px;
    padding-right: 2px;

    .card-body {
      padding: 2% 2% 0% 2%;
    }
  }
}
