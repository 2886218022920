@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.folder-contents {

  $column-count: 14;

  // display: table;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;

  font-size: 15px;

  overflow: auto;

  height: 100%;
  width: 100%;

  text-align: left;

  .row {
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
    flex-direction: row;
    // align-items: center; // vertical
    align-items: stretch;
    cursor: pointer;

    margin: 0;
    padding: 8px;
  }

  .col {
    display: flex;
    grid-column-gap: 8px;
    // padding: 0.8em 0.2em;
    min-height: 36px; // faz as linhas de pastas ter a mesma altura que docs

    min-width: (100% / $column-count);
    width: (100% / $column-count);
    max-width: (100% / $column-count);
    border-top: none;
    // border-right: solid 1px red; // REMOVE: dev only
    align-items: center;


    &.horizontal-center {
      justify-content: center;
    }
  }

  @for $i from 1 through $column-count {
    .col-#{$i} {
      @extend .col;
      min-width: (100% / $column-count) * $i;
      width: (100% / $column-count) * $i;
      max-width: (100% / $column-count) * $i;
    }
  }

  .table-header .row {
    border-top: none;
    border-bottom: 2px solid gray;
    font-weight: 450;
    color: 999;
  }

  .table-header .col {
    justify-content: baseline;
    grid-column-gap: 4px;
    user-select: none;
    &.sortable {
      cursor: pointer;
    }
  }


  .table-body .row {
    border-top: none;
    border-bottom: 1px solid $clr-txt-note;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .overdue {
    color: red;
  }



  .actions {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    grid-column-gap: 5px;
  }

  :global(.icon-documents) {
    width: 14px;
    height: 17px;
    margin: 0px 1px;
  }

  .members {
    div {
      display: flex;
      flex-wrap: wrap;
      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin: 0 2px;
      }
      span {
        padding: 10px 0;
        margin-left: 3px;
      }
    }
  }
}